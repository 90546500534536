'use client';

import { Box, Button, Center, Image, Link, Text } from '@/components';
import { useTranslation } from '@/hooks';
import FourOFour from '@/images/404/404.png';
import BackgroundMain from '@/images/404/backgroundMain.png';
import { routes } from '@/statics';
import { getBackgroundImage, getImageProps } from '@/utils';

const NotFound: React.FC = () => {
  const { t } = useTranslation('pages');
  const {
    props: { srcSet },
  } = getImageProps({ alt: '', src: BackgroundMain });
  const backgroundImage = getBackgroundImage(srcSet);

  return (
    <Box alignItems="center" flexDir="column" display="flex" h="100%">
      <Box
        bgImage={`/**/${backgroundImage}`}
        width="100%"
        height="100vh"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <Center mb="56px">
          <Text
            mt={{ base: '75px', md: '86px' }}
            fontSize={{ base: 'md', md: '2xl' }}
            fontWeight={500}
            color="text"
          >
            {t('fourOFour.canNotFindPage')}
          </Text>
        </Center>
        <Box width="100%" display="flex" justifyContent="center">
          <Center mt="5rem" width={{ base: '257px', md: '350px', lg: '530px' }}>
            <Image alt="404 error" src={FourOFour} />
          </Center>
        </Box>
        <Center mt="7rem" mb="7rem">
          <Button
            as={Link}
            href={routes.home.url}
            variant="outline"
            size={{ base: 'lg', md: 'xxl' }}
            colorScheme="gray"
            _hover={{
              bg: `transparent`,
            }}
          >
            {t('fourOFour.returnToMainPage')}
          </Button>
        </Center>
      </Box>
    </Box>
  );
};
export default NotFound;
